app.controller('ArticleCtrl', function ($scope, $rootScope, $stateParams, $state, $translate, $filter, ArticleService) {

  ArticleService.get($stateParams.id, $rootScope.lang).then(function (article) {
    $scope.article = article;

    $scope.article.date = $filter("date")(article.date, $rootScope.lang == 'fr' ? 'd/MM/yyyy' :'yyyy-MM-dd');
    
    $rootScope.$on('$translateChangeSuccess', function () {
      if ($state.current.name == 'app.article' && $state.params.id == $scope.article.id) {
        var metaTitleIdentification = 'meta-data.article-meta-title';
        var metaDescriptionIdentification = 'meta-data.article-meta-description';

        $translate([metaTitleIdentification, metaDescriptionIdentification], {articleName: $scope.article.title}).then(function (translations) {
          $rootScope.meta = {
            title: translations[metaTitleIdentification],
            description: translations[metaDescriptionIdentification]
          };
        });
      }
    });

  });

});
