app.service('ArticleService', function (CORPORATE_WEBSITE_API_URL, $http, WpRestApiAdapter) {

  var serviceBaseUrl = CORPORATE_WEBSITE_API_URL + 'posts';

  this.list = function (categoryId, lang) {
    return $http.get(serviceBaseUrl, {params: {categories: categoryId, lang: lang}}).then(function (results) {
      return WpRestApiAdapter.convertPosts(results.data).then(function (posts) {
        return posts;
      });
    });
  };

  this.get = function (id, lang) {
    return $http.get(serviceBaseUrl + '/' + id, {params: {lang: lang}}).then(function (results) {
      return WpRestApiAdapter.convertPost(results.data).then(function (post) {
        return post;
      });
    });
  };

});
