app.service('VehicleService', function (API_URL, $http, $translate) {

  var serviceBaseUrl = API_URL + 'vehicles';

  this.list = function (term) {
    return $http.get(serviceBaseUrl, {params: {term: term, lang: $translate.use()}}).then(function (results) {
      return results.data;
    });
  };

  this.get = function (id) {
    return $http.get(serviceBaseUrl + '/' + id, {params: {lang: $translate.use()}}).then(function (results) {
      return results.data;
    });
  };

});