app.controller('FooterCtrl', function($translate) {
  $translate([
    'footer.privacyLegacyUrl',
    'footer.popupTitle',
    'footer.popupText'
  ]).then(function(translation) {
    jQuery(document).ready(function() {
      if (!document.querySelector('.eupopup-container')) {
        jQuery(document)
          .euCookieLawPopup()
          .init({
            cookiePolicyUrl: translation['footer.privacyLegacyUrl'],
            popupTitle: translation['footer.popupTitle'],
            popupText: translation['footer.popupText']
          })
      }
    })
  })
})
