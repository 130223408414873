app.controller('SearchModalCtrl', function ($scope, $uibModalInstance, VehicleService, $filter) {

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.searchVehicles = function (term) {
    if (!term.length)
      return false;
    VehicleService.list(term).then(function (vehicles) {
      $scope.vehicles = $filter('orderBy')(vehicles, 'brand.name');
    });
  };

});
