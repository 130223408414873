app.controller('DistributorModalInstanceCtrl', function ($scope, $window, $uibModalInstance, DistributorService, distributorId) {

  $scope.loading = true;
  $scope.productScreens = [];

  DistributorService.get(distributorId).then(function (distributor) {
    $scope.loading = false;
    $scope.distributor = distributor;
    $scope.buildCarousel();
  }, function (e) {
    $scope.loading = false;
  });

  $scope.buildCarousel = function () {

    var itemsPerScreen = 2;
    var productScreens = [];
    var slide = [];
    for (var i = 0; i < $scope.distributor.products.length; i++) {
      if (slide.length === itemsPerScreen) {
        productScreens.push(slide);
        slide = [];
      }
      slide.push($scope.distributor.products[i]);
    }
    productScreens.push(slide);

    $scope.productScreens = productScreens;
  };

  angular.element($window).on('resize', function () {
    $scope.buildCarousel();
  });

  $scope.getItinerary = function (distributor) {
    $window.open('https://www.google.com/maps/dir//' + encodeURIComponent(
            distributor.address + ' ' +
            distributor.city + ' ' +
            distributor.postal_code + ' ' +
            distributor.country
        ));
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

});
