app.controller('ConsumptionCalculationModalInstanceCtrl', function ($scope, $uibModalInstance, $state, $translate, ProductService, calculation, vehicle) {

  $scope.vehicle = vehicle;
  $scope.calculation = calculation;
  $scope.motorization = JSON.parse(calculation.motorization);
  $scope.refuelingCount = Math.round($scope.motorization.adblue_consumption * calculation.mileage / 1000);

  $scope.loading = true;

  ProductService.list(calculation.mileage).then(function (products) {
    $scope.loading = false;
    $scope.products = products;
  }, function (e) {
    $scope.loading = false;
  });

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.showDistributors = function (productId) {
    $state.go('app.distributors', {lang: $translate.use(), productId:productId});
    $scope.close();
  };

});
