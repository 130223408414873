// Highlight searched characters on a live search
app.filter('highlight', function ($sce) {
  return function (text, terms) {
    angular.forEach(terms.split(' '), function (phrase) {
      if (phrase && phrase.length > 1) text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
          '<span class="highlighted">$1</span>')
    });
    return $sce.trustAsHtml(text)
  }
});

app.filter('truncate', function () {
  return function (value, wordwise, max, showAll) {
    if (!value) return '';
    if (showAll) return value;

    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');
      if (lastspace != -1) {
        value = value.substr(0, lastspace);
      }
    }

    return value + ' …';
  };
});

app.filter('htmlToPlaintext', function() {
      return function(text) {
        return  text ? String(text).replace(/<[^>]+>/gm, '') : '';
      };
    }
);