app.service('WpRestApiAdapter', function ($sce, $http, $q) {

  var self = this;

  self.convertPosts = function (rawPosts) {
    var promises = [];
    var posts = [];
    angular.forEach(rawPosts, function (rawPost) {
      promises.push(self.convertPost(rawPost).then(function (post) {
        posts.push(post);
      }));
    });

    return $q.all(promises).then(function () {
      return posts;
    });
  };

  self.convertPost = function (rawPost) {

    // get Post Categories
    return $http.get(rawPost['_links']['wp:term'][0].href).then(function (results) {

      var categories = [];
      angular.forEach(results.data, function (category) {
        categories.push({
          'id': category.id,
          'name': category.name
        });
      });

      if (typeof rawPost['_links'] != 'undefined' && typeof rawPost['_links']['wp:featuredmedia'] != 'undefined' && rawPost['_links']['wp:featuredmedia'].length) {
        return $http.get(rawPost['_links']['wp:featuredmedia'][0].href).then(function (featuredMedia) {
          return {
            'id': rawPost.id,
            'title': rawPost.title.rendered,
            'content': $sce.trustAsHtml(rawPost.content.rendered),
            'date': rawPost.date,
            'categories': categories,
            'thumbnail': featuredMedia.data['media_details']['sizes']['card-l']['source_url']
          };
        });
      } else {
        return Promise.resolve({
          'id': rawPost.id,
          'title': rawPost.title.rendered,
          'content': $sce.trustAsHtml(rawPost.content.rendered),
          'date': rawPost.date,
          'categories': categories
        });
      }
    });
  };

  self.convertCategories = function (rawCategories) {
    var categories = [];
    angular.forEach(rawCategories, function (rawCategory) {
      categories.push({
        'id': rawCategory.id,
        'name': rawCategory.name
      });
    });
    return categories;
  };

  self.convertInformations = function (rawInformations) {
    var promises = [];
    var informations = [];
    angular.forEach(rawInformations, function (rawInformation) {
      promises.push(self.convertInformation(rawInformation).then(function (information) {
        informations.push(information);
      }));
    });

    return $q.all(promises).then(function () {
      return informations;
    });
  };

  self.convertInformation = function (rawInformation) {

    // get Information Thumbnail
    if (typeof rawInformation['_links'] != 'undefined' && typeof rawInformation['_links']['wp:featuredmedia'] != 'undefined' && rawInformation['_links']['wp:featuredmedia'].length) {
      return $http.get(rawInformation['_links']['wp:featuredmedia'][0].href).then(function (featuredMedia) {
        return {
          'id': rawInformation.id,
          'title': rawInformation.title.rendered,
          'content': $sce.trustAsHtml(rawInformation.content.rendered),
          'date': rawInformation.date,
          'thumbnail': typeof featuredMedia.data['media_details']['sizes']['card-l'] != 'undefined' ? featuredMedia.data['media_details']['sizes']['card-l']['source_url'] : null
        };
      });
    } else {
      return Promise.resolve({
        'id': rawInformation.id,
        'title': rawInformation.title.rendered,
        'content': $sce.trustAsHtml(rawInformation.content.rendered),
        'date': rawInformation.date
      });
    }
  }

});
