app.service('CategoryService', function (CORPORATE_WEBSITE_API_URL, $http, WpRestApiAdapter, $translate) {

  var serviceBaseUrl = CORPORATE_WEBSITE_API_URL + 'categories';

  this.list = function () {
    return $http.get(serviceBaseUrl, {params: {lang: $translate.use()}}).then(function (results) {
      return WpRestApiAdapter.convertCategories(results.data);
    });
  };

});
