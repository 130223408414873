app.controller('HomeCtrl', function ($scope, $rootScope, $translate, $uibModal, $filter, $stateParams, BrandService, VehicleService) {

  BrandService.list().then(function (brands) {
    $scope.brands = brands;
  });

  $scope.showDetail = function (id) {
    BrandService.get(id).then(function (brand) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: './templates/partials/modals/brand-detail.html',
        windowTemplateUrl: './templates/partials/modals/layout-side.html',
        controller: 'BrandModalInstanceCtrl',
        resolve: {
          brand: function () {
            return brand;
          }
        }
      });
    });
  };

  $scope.searchVehicles = function (term) {
    if (!term.length)
      return false;
    VehicleService.list(term).then(function (vehicles) {
      $scope.vehicles = $filter('orderBy')(vehicles, 'brand.name');
    });
  };

  if ($stateParams.brandId) {
    $scope.showDetail($stateParams.brandId);
  }

});
