app.controller('DistributorsCtrl', function ($scope, $rootScope, $translate, $window, $timeout, $stateParams, DistributorService, ProductService, $uibModal, APP_URL) {

  ProductService.list().then(function (products) {
    $scope.products = products;
    $scope.selectedProduct = $stateParams.productId;
  });

  DistributorService.list($stateParams.productId).then(function (distributors) {
    $scope.distributors = distributors;
  });

  $scope.map = {
    center: {latitude: 48.8566, longitude: 2.3522},
    zoom: 5,
    options: {
      scrollwheel: false,
    },
    markerOptions: {
      icon: APP_URL + 'dist/img/pointer.png'
    }
  };

  // TODO : Uncomment that code (not working for Seb because he's on MAMP instead of Vagrant
  // if ($window.navigator.geolocation) {
  //   $window.navigator.geolocation.getCurrentPosition(function (position) {
  //     $scope.$apply(function () {
  //       $scope.map.center = position;
  //        $scope.map.zoom = 7;
  //     });
  //   });
  // }

  $scope.getDistributors = function(productId) {
    $timeout(function(){
      DistributorService.list(productId).then(function (distributors) {
        $scope.distributors = distributors;
        $scope.map.zoom = 5;
      });
    });

  };

  $scope.showDetail = function (id) {

    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: './templates/partials/modals/distributor-detail.html',
      windowTemplateUrl: './templates/partials/modals/layout-side.html',
      controller: 'DistributorModalInstanceCtrl',
      resolve: {
        distributorId: function () {
          return id;
        }
      }
    });
  };

});
