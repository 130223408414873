app.controller('InformationsCtrl', function ($scope, $rootScope, $sce, $timeout, $translate, InformationService, BrandService) {

    $timeout($('#twitter-feed-wrapper').html($('#twitter-feed').html()), 2000);

    $scope.loading = true;

    InformationService.list().then(function (informations) {
        $scope.loading = false;
        $scope.informations = informations;
    });

    BrandService.list().then(function (brands) {
        $scope.brands = brands;
    });

});
