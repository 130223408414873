app.controller('HeaderCtrl', function ($scope, $rootScope, $uibModal, $state) {

  var self = this;

  self.showSearchModal = function () {
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: './templates/partials/modals/search.html',
      windowTemplateUrl: './templates/partials/modals/layout-center.html',
      controller: 'SearchModalCtrl'
    });
  };

  self.changeLanguage = function (lang) {
    $state.go('app.home', {lang: lang});
  };

});
