var app = angular.module('passengerCar', [
  'ui.router',
  'ui.bootstrap',
  'uiGmapgoogle-maps',
  'rzModule',
  'angular-carousel',
  'pascalprecht.translate',
  'ngSanitize',
  'ngMeta'
])

app.config(function(
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $translateProvider,
  uiGmapGoogleMapApiProvider,
  API_URL,
  ngMetaProvider
) {
  // For any unmatched url, send to 404
  $urlRouterProvider.otherwise('404')
  $urlRouterProvider.when('/', '/en/')

  uiGmapGoogleMapApiProvider.configure({
    // Buddyweb's API Key
    key: 'AIzaSyCDo-0XTPLDviYQAlcioSGuyXe5VJ6R3JY'
  })

  $translateProvider.useUrlLoader(API_URL + 'translations')

  $locationProvider.html5Mode(true).hashPrefix('!')

  ngMetaProvider.setDefaultTag('og:type', 'website')
  ngMetaProvider.setDefaultTitleSuffix(' | AdBlue®4you')

  /*
   Routes
   */

  // English (default)
  $stateProvider
    .state('app', {
      abstract: true,
      url: '/{lang}',
      template: '</ui-view>',
      views: {
        content: {
          templateUrl: '<div ui-view="content" class="content"></div>'
        }
      },
      resolve: {
        translateReady: [
          '$translate',
          '$stateParams',
          function($translate, $stateParams) {
            $translate.preferredLanguage($stateParams.lang)
            return $translate.onReady()
          }
        ]
      }
    })
    .state('app.home', {
      url: '/?brandId',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/pages/home.html',
          controller: 'HomeCtrl'
        },
        footer: {
          templateUrl: 'templates/partials/footer.html'
        }
      },
      resolve: {
        data: function($translate, ngMeta, $stateParams) {
          $translate.use($stateParams.lang)
          var metaTitleIdentification = 'meta-data.home-meta-title'
          var metaDescriptionIdentification = 'meta-data.home-meta-description'

          $translate([
            metaTitleIdentification,
            metaDescriptionIdentification
          ]).then(function(translations) {
            ngMeta.setTitle(
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'og:title',
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'description',
              translations[metaDescriptionIdentification]
            )
            ngMeta.setTag(
              'og:description',
              translations[metaDescriptionIdentification]
            )
            ngMeta.setTag(
              'og:image',
              'http://greenchem-adblue4you.com/dist/img/logo-greenchem.png'
            )
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('app.vehicle', {
      url: '/vehicle/{id}',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/vehicles/show.html',
          controller: 'VehicleCtrl'
        },
        footer: {
          templateUrl: 'templates/partials/footer.html'
        }
      },
      resolve: {
        data: function(
          $translate,
          ngMeta,
          VehicleService,
          $stateParams,
          APP_URL
        ) {
          $translate.use($stateParams.lang)

          VehicleService.get($stateParams.id).then(function(vehicle) {
            var metaTitleIdentification = 'meta-data.vehicle-meta-title'

            $translate([metaTitleIdentification], {
              vehicleName: vehicle.brand.name + ' ' + vehicle.name
            }).then(function(translations) {
              ngMeta.setTitle(
                translations[metaTitleIdentification] + ' | AdBlue®4you'
              )
              ngMeta.setTag(
                'og:title',
                translations[metaTitleIdentification] + ' | AdBlue®4you'
              )
              ngMeta.setTag('og:image', APP_URL + '/' + vehicle.image_url)
            })
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('app.distributors', {
      url: '/distributors?productId',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/distributors/list.html',
          controller: 'DistributorsCtrl'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      },
      resolve: {
        data: function($translate, ngMeta, $stateParams) {
          $translate.use($stateParams.lang)
          var metaTitleIdentification = 'adblue-near-you.filter-title'
          $translate([metaTitleIdentification]).then(function(translations) {
            ngMeta.setTitle(
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'og:title',
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('app.informations', {
      url: '/informations',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/informations/list.html',
          controller: 'InformationsCtrl'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      },
      resolve: {
        data: function($translate, ngMeta, $stateParams) {
          $translate.use($stateParams.lang)
          var metaTitleIdentification = 'meta-data.infos-meta-title'
          var metaDescriptionIdentification = 'meta-data.infos-meta-description'

          $translate([
            metaTitleIdentification,
            metaDescriptionIdentification
          ]).then(function(translations) {
            ngMeta.setTitle(
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'og:title',
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'description',
              translations[metaDescriptionIdentification]
            )
            ngMeta.setTag(
              'og:description',
              translations[metaDescriptionIdentification]
            )
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('app.articles', {
      url: '/articles',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/articles/list.html',
          controller: 'ArticlesCtrl'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      },
      resolve: {
        data: function(ngMeta, $translate, $stateParams) {
          $translate.use($stateParams.lang)
          var metaTitleIdentification = 'meta-data.articles-meta-title'
          var metaDescriptionIdentification =
            'meta-data.articles-meta-description'

          $translate([
            metaTitleIdentification,
            metaDescriptionIdentification
          ]).then(function(translations) {
            ngMeta.setTitle(
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'og:title',
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'description',
              translations[metaDescriptionIdentification]
            )
            ngMeta.setTag(
              'og:description',
              translations[metaDescriptionIdentification]
            )
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('app.article', {
      url: '/articles/{id}',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/articles/show.html',
          controller: 'ArticleCtrl'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      }
    })
    .state('app.legalNotice', {
      url: '/legal-notice',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/pages/legal-notice.html',
          controller: 'LegalNoticeCtrl'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      },
      resolve: {
        data: function($translate, ngMeta, $stateParams) {
          $translate.use($stateParams.lang)
          var metaTitleIdentification = 'meta-data.legal-notice-meta-title'
          var metaDescriptionIdentification =
            'meta-data.legal-notice-meta-description'

          $translate([
            metaTitleIdentification,
            metaDescriptionIdentification
          ]).then(function(translations) {
            ngMeta.setTitle(
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'og:title',
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'description',
              translations[metaDescriptionIdentification]
            )
            ngMeta.setTag(
              'og:description',
              translations[metaDescriptionIdentification]
            )
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('app.contact', {
      url: '/contact',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/pages/contact.html',
          controller: 'ContactCtrl'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      },
      resolve: {
        data: function($translate, ngMeta, $stateParams) {
          $translate.use($stateParams.lang)
          var metaTitleIdentification = 'meta-data.contact-meta-title'
          var metaDescriptionIdentification =
            'meta-data.contact-meta-description'

          $translate([
            metaTitleIdentification,
            metaDescriptionIdentification
          ]).then(function(translations) {
            ngMeta.setTitle(
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'og:title',
              translations[metaTitleIdentification] + ' | AdBlue®4you'
            )
            ngMeta.setTag(
              'description',
              translations[metaDescriptionIdentification]
            )
            ngMeta.setTag(
              'og:description',
              translations[metaDescriptionIdentification]
            )
          })
        }
      },
      meta: {
        disableUpdate: true
      }
    })
    .state('404', {
      url: '/404',
      views: {
        header: { templateUrl: 'templates/partials/header.html' },
        content: {
          templateUrl: 'templates/errors/404.html'
        },
        footer: { templateUrl: 'templates/partials/footer.html' }
      },
      data: {
        meta: {
          title: 'Page not found - Error 404 | AdBlue®4you'
        }
      }
    })
})

app.run(function($rootScope, $state, $stateParams, $translate, ngMeta) {
  ngMeta.init()

  $rootScope.$on('$stateChangeSuccess', function() {
    document.body.scrollTop = document.documentElement.scrollTop = 0

    $rootScope.titleUpdated = false

    if ($stateParams.lang !== undefined) {
      $rootScope.lang = $stateParams.lang
      $translate.use($stateParams.lang)
    } else {
      $rootScope.lang = 'en'
      $translate.use($rootScope.lang)
    }
  })
})
