app.service('DistributorService', function (API_URL, $http, $translate) {

  var serviceBaseUrl = API_URL + 'distributors';

  this.list = function (productId) {
    return $http.get(serviceBaseUrl, {params: {lang: $translate.use(), productId: productId}}).then(function (results) {
      return results.data;
    })
  };

  this.get = function (id) {
    return $http.get(serviceBaseUrl + '/' + id, {params: {lang: $translate.use()}}).then(function (results) {
      return results.data;
    });
  };

});
