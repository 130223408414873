app.service('NewsletterService', function (API_URL, $http) {

  var serviceBaseUrl = API_URL + 'prospects';

  this.store = function (email) {
    return $http.post(serviceBaseUrl, {email: email}).then(function (results) {
      return results;
    });
  };

});
