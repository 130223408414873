app.service('ProductService', function (API_URL, $http, $rootScope) {

  var serviceBaseUrl = API_URL + 'products';

  this.list = function (mileage) {
    return $http.get(serviceBaseUrl, {params: {mileage: mileage, lang: $rootScope.lang}}).then(function (results) {
      return results.data;
    });
  };

});
