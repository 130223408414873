app.controller('VehicleCtrl', function ($scope, $rootScope, $translate, $state, $stateParams, $uibModal, $q, VehicleService, InformationService) {

  VehicleService.get($stateParams.id).then(function (vehicle) {
    $scope.vehicle = vehicle
  })

  // We get 3 defined Informations from server
  if ($rootScope.lang == 'fr') {
    var definedInformations = [1840, 1694, 1879]
  } else if ($rootScope.lang == 'es') {
    var definedInformations = [1843, 1709, 1881]
  } else if ($rootScope.lang == 'it') {
    var definedInformations = [1849, 1723, 1885]
  } else if ($rootScope.lang == 'pt') {
    var definedInformations = [1855, 1735, 1889]
  } else if ($rootScope.lang == 'nl') {
    var definedInformations = [1852, 1729, 1887]
  } else if ($rootScope.lang == 'cs') {
    var definedInformations = [1837, 1702, 1877]
  } else if ($rootScope.lang == 'sk') {
    var definedInformations = [301, 2410, 2429]
  } else if ($rootScope.lang == 'hu') {
    var definedInformations = [1846, 1717, 1883]
  } else if ($rootScope.lang == 'pl') {
    var definedInformations = [1858, 1741, 1891]
  } else {
    // default english (we have to do that for each language)
    var definedInformations = [301, 526, 528]
  }

  var informationPromises = []
  angular.forEach(definedInformations, function (id) {
    informationPromises.push(InformationService.get(id).then(function (information) {
      return information
    }))
  })
  $q.all(informationPromises).then(function (results) {
    $scope.informations = results
  })

  $scope.calculateConsumption = function (calculation) {
    var modalInstance = $uibModal.open({
      animation: true,
      templateUrl: './templates/partials/modals/consumption-calculation.html',
      windowTemplateUrl: './templates/partials/modals/layout-center.html',
      controller: 'ConsumptionCalculationModalInstanceCtrl',
      resolve: {
        calculation: function () {
          return calculation
        },
        vehicle: function () {
          return $scope.vehicle
        }
      }
    })
  }

})
