app.controller('ArticlesCtrl', function ($scope, $rootScope, $translate, ArticleService, CategoryService) {

  CategoryService.list().then(function (categories) {
    $scope.categories = categories;
  });

  $scope.getArticles = function (category) {
    if (category) {
      $scope.selectedCategory = category;
      var selectedCategoryId = category.id;
    } else {
      $scope.selectedCategory = null;
      var selectedCategoryId = null;
    }

    $scope.articles = [];
    $scope.loading = true;

    ArticleService.list(selectedCategoryId, $rootScope.lang).then(function (articles) {
      $scope.loading = false;
      $scope.articles = articles;
    });
  };

  $scope.getArticles();

});
