app.controller('ContactCtrl', function ($scope, $http, $timeout, API_URL, $rootScope, $translate) {

  $scope.submitForm = function (isValid) {
    $scope.submitted = true;
    if (isValid) {
      $scope.loading = true;

      // build formData
      var formData = new FormData();
      if ($scope.contact.name) formData.append('name', $scope.contact.name);
      if ($scope.contact.company) formData.append('company', $scope.contact.company);
      if ($scope.contact.email) formData.append('email', $scope.contact.email);
      if ($scope.contact.message) formData.append('message', $scope.contact.message);

      $http.post(API_URL + 'contacts', formData, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
          .then(function (data, status, headers, config) {
            $scope.loading = false;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            $scope.contact = false;
            $scope.submitted = false;
            $scope.submitStatus = 'succeeded';
            // send analytics event

            $timeout(hideSumbitMessage, 6000);
          }, function (data, status, header, config) {
            $scope.loading = false;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            $scope.submitStatus = 'failed';
            $timeout(hideSumbitMessage, 6000);
          });
    }
  };

  function hideSumbitMessage() {
    return $scope.submitStatus = null;
  }

});
