app.service('InformationService', function (CORPORATE_WEBSITE_API_URL, $http, $rootScope, WpRestApiAdapter, $translate) {

  var serviceBaseUrl = CORPORATE_WEBSITE_API_URL + 'informations';

  this.list = function () {
    return $http.get(serviceBaseUrl, {params: {lang: $rootScope.lang}}).then(function (results) {
      return WpRestApiAdapter.convertInformations(results.data);
    })
  };

  this.get = function (id) {
    return $http.get(serviceBaseUrl + '/' + id, {params: {lang: $translate.use()}}).then(function (results) {
      return WpRestApiAdapter.convertInformation(results.data);
    });
  };

});
