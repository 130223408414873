app.controller('NewsletterCtrl', function ($scope, NewsletterService, $filter, $timeout) {

  var self = this;

  self.submit = function (email) {
    NewsletterService.store(email).then(function (results) {
      $scope.subscriptionMessage = {
        content: $filter('translate')('footer.newsletter-success-message'),
        class: 'success'
      };
      $timeout(self.clearMessage, 4000);
    }, function (error) {
      $scope.subscriptionMessage = {
        content: $filter('translate')('footer.newsletter-error-message'),
        class: 'danger'
      };
      $timeout(self.clearMessage, 4000);
    });
  };

  self.clearMessage = function () {
    $scope.subscriptionMessage = false;
  };

});
